<template>
  <MainLayout>
    <!-- popup подтверждающий удаление -->
    <DeletePopupAdmin
      title="администратора"
      :name="fullName"
      :dialog="dialog"
      @closeDeletePopup="closeDeletePopup"
      :delete-function="deleteAdmin"
    />
    <!-- /popup подтверждающий удаление-->

    <template v-slot:block>
      {{
        sourceObject.results
          ? sourceObject.results.is_active === false
            ? "Заблокирован -"
            : ""
          : ""
      }}
    </template>
    <template v-slot:navbar-title>
      {{ sourceObject.results ? sourceObject.results.name : "" }}
    </template>
    <template v-slot:navbar-btn-wrap>
      <DeletePopup
        name="Организацию"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :delete-function="deleteCompany"
      />
      <BlockPopup
        v-if="sourceObject.results && sourceObject.results.is_active"
        name="Организацию"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :block-function="blockCompany"
      />
      <UnBlockPopup
        v-if="!(sourceObject.results && sourceObject.results.is_active)"
        name="Организацию"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :un-block-function="unBlockCompany"
      />
      <router-link
        v-if="urlQuery && urlQuery.id"
        :to="`/companyEdit/` + urlQuery.id"
        class="btn btn-edit btn_color_grey"
        >редактировать</router-link
      >
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <InfoLayout :tabs="infoTabs">
          <template v-slot:recv>
            <div class="keep__title">
              <p class="keep__text">Договор и оплата</p>
              <!--              <button class="btn btn_color_grey btn-edit">Редактировать</button>-->
            </div>
            <BasicTableWithoutHeader
              :table-data="getDataRequisites"
              v-if="sourceObject.results"
            />
          </template>
          <template v-slot:admins>
            <div class="keep__title">
              <p class="keep__text">Администраторы</p>
              <!--              <button class="btn btn_color_grey btn-edit">Редактировать</button>-->
              <router-link
                to="createAdmin"
                append
                class="btn btn-add btn_color_green"
                >Добавить Администратора</router-link
              >
            </div>

            <BasicTable
              :header="headers"
              :table-data="getDataAdmins.results"
              deleteButtonDirectionStart
            >
              <template v-slot:delete-td-start="{ itemChild }">
                <td class="btn-delete-wrap">
                  <button
                    @click="clickDelete(itemChild)"
                    class="form__btn-delete btn btn-delete"
                    style="background-color: #eb5c6d"
                  ></button>
                </td>
              </template>
            </BasicTable>
          </template>
        </InfoLayout>
      </template>
      <template v-slot:serviceGroups>
        <ServesOrganizationGroups />
      </template>
      <template v-slot:objects>
        <ServesOrganizationObject />
      </template>
      <template v-slot:controllers>
        <ServesOrganizationControllers />
      </template>
      <template v-slot:technics>
        <ServesOrganizationStaff />
      </template>
      <template v-slot:applications>
        <ServesOrganizationServiceTasks />
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import InfoLayout from "@/components/MoreInfo/InfoLayout";
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTable from "@/components/tables/BasicTable";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import * as query from "@/helpers/query";
import ServesOrganizationControllers from "@/components/info/ServesOrganization/ServesOrganizationControllers";
import ServesOrganizationObject from "@/components/info/ServesOrganization/ServesOrganizationObject";
import ServesOrganizationGroups from "@/components/info/ServesOrganization/ServesOrganizationGroups";
import ServesOrganizationServiceTasks from "@/components/info/ServesOrganization/ServesOrganizationServiceTasks";
import DeletePopup from "@/components/popup/DeletePopup";
import ServesOrganizationStaff from "@/components/info/ServesOrganization/ServesOrganizationStaff";
import BlockPopup from "@/components/popup/BlockPopup";
import UnBlockPopup from "@/components/popup/UnBlockPopup";
import DeletePopupAdmin from "@/components/popup/DeletePopupCommon";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";
export default {
  name: "ServiceOrganizationInfosPages",
  components: {
    UnBlockPopup,
    BlockPopup,
    ServesOrganizationStaff,
    DeletePopup,
    BasicTableWithoutHeader,
    BasicTable,
    SlideLayout,
    MainLayout,
    InfoLayout,
    ServesOrganizationControllers,
    ServesOrganizationObject,
    ServesOrganizationGroups,
    ServesOrganizationServiceTasks,
    DeletePopupAdmin
  },
  data: () => ({
    dialog: false,
    idAdmin: "", // id админа
    fullName: "",

    type: "service",
    queries: query.organizations,
    infoTabs: [
      {
        text: "Реквизиты",
        value: "recv"
      },
      {
        text: "Администраторы",
        value: "admins"
      }
    ],
    headers: [
      // {
      //   value: "id",
      //   text: "№"
      // },
      {
        value: "id",
        text: "ID",
        width: 25
      },
      {
        renderFunction: value =>
          `${value.last_name} ${value.first_name} ${value.middle_name || ""}`,
        text: "ФИО"
      },
      {
        renderFunction: value => phoneFilter.execute(value?.phone_number),
        text: "Телефон"
      }
    ],
    tableData: [
      {
        text: "Дата договора",
        value: "20.04.2020"
      },
      {
        text: "Файл (скан) договора",
        value: "Договор №512656532158-786"
      },
      {
        text: "Тип оплаты",
        value: "Через дилера"
      },
      {
        text: "Эквайринг",
        value: "Сбербанк"
      }
    ]
  }),
  methods: {
    deleteCompany() {
      this.$store
        .dispatch("deleteCompany", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.serviceOrganization)
          );
        });
    },
    blockCompany() {
      this.$store
        .dispatch("blockOrganization", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.ban(successMessageDictionary.serviceOrganization)
          );
        });
    },
    unBlockCompany() {
      this.$store
        .dispatch("unBlockOrganization", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.unBan(successMessageDictionary.serviceOrganization)
          );
        });
    },

    clickDelete(obj) {
      this.idAdmin = obj.id;
      this.fullName = `${obj.last_name} ${obj.first_name} ${obj.middle_name}`;
      this.dialog = true;
    },

    closeDeletePopup(data) {
      this.dialog = data;
    },

    deleteAdmin() {
      this.isDateLoaded = true;
      this.$store
        .dispatch("deleteAdmin", {
          id: this.idAdmin,
          organizationId: this.$route.params.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.admin)
          );
        })
        .finally(() => {
          this.$store.dispatch("fetchAdmins").finally(() => {
            this.isDateLoaded = false;
          });
        });
    }
  },
  computed: {
    slideTabs() {
      return [
        {
          text: "Общая информация",
          value: "info",
          icon: "i"
        },
        {
          text: "Группы объектов",
          value: "serviceGroups",
          icon: null,
          count: this.serviceGroupsList
        },
        {
          text: "Объекты",
          value: "objects",
          icon: null,
          count: this.sourceObject.results?.service_organizations_objects_count
          // count: 127
        },
        {
          text: "Контроллеры",
          value: "controllers",
          icon: null,
          count: this.sourceObject.results
            ?.service_organizations_objects_controllers_count

          // count: 348
        },
        {
          text: "Техники",
          value: "technics",
          icon: null,
          count: this.sourceObject.results
            ?.service_organizations_technicians_count

          // count: 18
        },
        {
          text: "Заявки",
          value: "applications",
          icon: null
          // count: 4568
        }
      ];
    },
    sourceObject() {
      return this.$store.getters.getDealerInfo;
    },
    getDataAdmins() {
      return this.$store.getters.getAdmins;
    },
    serviceGroupsList() {
      return this.$store.getters.getPageServiceGroupsList.count;
    },
    getDataRequisites() {
      return [
        {
          text: "Полное название организации",
          value: this.sourceObject.results.full_org_name
        },
        {
          text: "Краткое название организации",
          value: this.sourceObject.results.name
        },
        {
          text: "Город",
          value: this.sourceObject.results.city_details.name
        },
        {
          text: "Тип организации",
          value: this.sourceObject.results.ownership
        },
        {
          text: "Руководитель",
          value: this.sourceObject.results.director
        },
        {
          text: "ИНН",
          value: this.sourceObject.results.TIN
        },
        {
          text: "ОРГН",
          value: this.sourceObject.results.PSRN
        },
        {
          text: "БИК",
          value: this.sourceObject.results.RCBIC
        },
        {
          text: "КПП",
          value: this.sourceObject.results.IEC
        },
        {
          text: "Название банка",
          value: this.sourceObject.results.bank
        },
        {
          text: "К/c",
          value: this.sourceObject.results.CA
        },
        {
          text: "Р/c",
          value: this.sourceObject.results.giro
        },
        {
          text: "Адрес организации",
          value: this.sourceObject.results.address
        },
        {
          text: "Фактический адрес организации",
          value: this.sourceObject.results.actual_address
        },
        // {
        //   text: "Номер договора",
        //   value: this.sourceObject.results.contract
        //     ? this.sourceObject.results.contract.number
        //     : "-"
        // },
        {
          text: "Телефон",
          value: phoneFilter.execute(this?.sourceObject?.results?.phone_number)
        },
        {
          text: "E-mail",
          value: this.sourceObject.results.email
        }
      ];
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  created() {
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, type: this.type }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchDealerInfo").finally(() => {
      this.isDateLoaded = false;
    });
    this.$store.dispatch("fetchServiceGroupsList").finally(() => {
      this.isDateLoaded = false;
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchAdmins").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchDealerInfo").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearOrganizationPage").then(() => next());
  }
};
</script>

<style scoped></style>
